import React from 'react';
import tap from '../../../images/tape.png';
import styles from './contactdetails.module.css';

const ContactDetails = () => {
    return (
        <>
        <div className={styles.ContactDetails}>
            <div className={styles.tap}>
                <img src={tap} alt="tap"/>
            </div> 
            <h2>Address <i className="icon-pin pull-right"></i></h2>
            <p>Sam Sand Dunes, Jaisalmer - Sam - Dhanana Rd, Jaisalmer, Rajasthan 345001</p>
            <h2>Help Center <i className="icon-help pull-right"></i></h2>
            <p>Call us or drop a message. <br/> We are available 24x7</p>
            <p>+ 91 88900 67412 / +91 90248 12494</p>
            <p>
                <a href="mailto://info@jaisalmerdesertsafari.in">info@jaisalmerdesertsafari.in</a>
            </p>
        </div>
        <div className={styles.HelpCenter}>
            <div className={styles.HelpIcon}>
                <i className="icon_set_1_icon-57"></i>
            </div>
            <h4>Need <span className="text-primary">Help?</span></h4>
            <p className={styles.mobile}>+ 91 88900 67412</p> 
        </div>
        </>
    );
};

export default ContactDetails;
