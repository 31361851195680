import React, { useState } from 'react';
import axios from '../../../axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import styles from './contactform.module.css';

const ContactForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [isSuccess, setIsSuccess] = useState('');

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await axios.post('/contact/v1/submit', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        message: message,
      });
      setIsLoading(false);
      setIsSuccess(true);
      await new Promise(resolve => setTimeout(() => resolve(), 3000));
      setIsSuccess(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPhone('');
      setMessage('');
    } catch (error) {
      console.log('Failed!');
    }

    setIsLoading(false);
  };

  return (
    <div className={styles.ContactFromBlock}>
      <div className={styles.ContantTitleBlock}>
        <div className={styles.ContactIcon}>
          <i className='icon-pencil'></i>
        </div>
        <div className={styles.ContantTitle}>
          <h2>Fill the form below</h2>
          <p>We will get back to you!</p>
        </div>
      </div>
      <div className={styles.ContactForm}>
        <Form className='mb-5'>
          <Row>
            <Col md={6} sm={12}>
              <Form.Group controlId='firstName'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={6} sm={12}>
              <Form.Group controlId='lastName'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Last Name'
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} xs={12}>
              <Form.Group controlId='email'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col sm={6} xs={12}>
              <Form.Group controlId='phone_number'>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type='tel'
                  placeholder='Enter Phone Number'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId='message'>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder='Write your message'
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant='primary'
                onClick={handleSubmit}
                disabled={isLoading || isSuccess}
              >
                {isLoading && (
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                    variant='light'
                  />
                )}
                {isSuccess && (
                  <i className='icon-check-1' style={{ fontSize: 18 }}></i>
                )}
                {!isSuccess && !isLoading && 'Submit'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ContactForm;
