import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero/StyledHero';
import ContactFrom from '../components/ContactPage/ContactForm/ContactForm';
import ContactDetails from '../components/ContactPage/ContactDetails/ContactDetails';

const ContactUs = ({data}) => {
    return (
        <Layout title="Contact US">
            <StyledHero image={data.heroImage.childImageSharp.fluid}>
                <h1 style={{textTransform:'uppercase'}}>Contact Us</h1>
                <h4>Drop us a line and we will get back to you!</h4> 
            </StyledHero>
            <Container>
                <Row className="my-5">
                    <Col md={8} sm={12}>
                        <ContactFrom/>
                    </Col>
                    <Col md={4} sm={12}>
                        <ContactDetails/>
                    </Col>
                </Row>  
            </Container>
            <Container className="p-0" fluid>
                <iframe title="Tour map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14241.3702176716!2d70.5342348!3d26.8290549!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x109ad2a83a3ded78!2sSunrise%20Desert%20Resort!5e0!3m2!1sen!2sin!4v1582884968789!5m2!1sen!2sin" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen=""></iframe>
            </Container>

            
        </Layout>
    );
};

export const query = graphql `
    query{
        heroImage:file(relativePath:{eq:"Heros/contact-page.jpg"}){
            id 
            childImageSharp{
                fluid(maxWidth:1920,maxHeight:800){
                ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
export default ContactUs;
